import React from "react";
import "./DistrictSection.css";

function DistrictSection() {
  return (
    <div className="homeDisctrictContainer">
      <div className="right">
        <div className="disctrict-handicraft-card banda">
          <div className="craft-name">
            <p>Banda</p>
          </div>
        </div>
        <div className="disctrict-handicraft-card chitrakoot">
          <div className="craft-name">
            <p>Chitrakoot</p>
          </div>
        </div>
        <div className="disctrict-handicraft-card jhansi">
          <div className="craft-name">
            <p>Jhansi</p>
          </div>
        </div>
        <div className="disctrict-handicraft-card Jalaun">
          <div className="craft-name">
            <p>Jalaun</p>
          </div>
        </div>
        <div className="disctrict-handicraft-card Hamirpur">
          <div className="craft-name">
            <p>Hamirpur</p>
          </div>
        </div>
        <div className="disctrict-handicraft-card Mahoba">
          <div className="craft-name">
            <p>Mahoba</p>
          </div>
        </div>
        <div className="disctrict-handicraft-card Lalitpur">
          <div className="craft-name">
            <p>Lalitpur</p>
          </div>
        </div>
      </div>
      <div class="monument">&nbsp;</div>
    </div>
  );
}

export default DistrictSection;
