import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import HomePage from "./screens/Home/HomePage";
import DetailPage from "./components/Detail/DetailPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import DetailPageMahoba from "./components/Detail/DetailPageMahoba";
import DetailPageBanda from "./components/Detail/DetailPageBanda";
import ComingSoon from "./components/CominSoon/ComingSoon";
import UpcomingEvents from "./components/CominSoon/UpcomingEvents";
import AboutUs from "./screens/AboutUs/AboutUs";
import BandaSection from "./components/Banda/BandaSection";
import ChitrakootSection from "./components/Chitrakoot/ChitrakootSection";
import JhansiSection from "./components/Jhansi/JhansiSection";
import JalaunSection from "./components/Jalaun/JalaunSection";
import HamirpurSection from "./components/Hamirpur/HamirpurSection";
import MahobaSection from "./components/Mahoba/MahobaSection";
import DetailPageJhansi from "./components/Detail/DetailPageJhansi";
import DetailPageLalitpur from "./components/Detail/DetailsPageLalitpur";
import DetailPageJalaun from "./components/Detail/DetailsPageJalaun";

import ThematicExhibitionBanda from "./components/Detail/ThematicExhibitionBanda";
import ContactUs from "./screens/ContactUs/ContactUs";
import ShopPage from "./screens/Shop/ShopPage";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/detail/chitrakoot" element={<DetailPage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/detail/mahoba" element={<DetailPageMahoba />} />
            <Route path="/detail/banda" element={<DetailPageBanda />} />
            <Route path="/detail/jhansi" element={<DetailPageJhansi />} />
            <Route path="/detail/lalitpur" element={<DetailPageLalitpur />} />
            <Route path="/detail/jalaun" element={<DetailPageJalaun />} />
            <Route
              path="/thematic-exhibition/banda"
              element={<ThematicExhibitionBanda />}
            />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/upcoming-events" element={<UpcomingEvents />} />

            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/banda" element={<BandaSection />} />
            <Route path="/chitrakoot" element={<ChitrakootSection />} />
            <Route path="/jhansi" element={<JhansiSection />} />
            <Route path="/jalaun" element={<JalaunSection />} />
            <Route path="/hamirpur" element={<HamirpurSection />} />
            <Route path="/mahoba" element={<MahobaSection />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
