import React, { useEffect, useState } from "react";
import "./BandaSection.css";
import icon1 from "../../assets/logo/sajarstone.png";
import icon2 from "../../assets/logo/woodencraft.png";
import icon3 from "../../assets/logo/blockpaintingicon.png";

const arts = [
  {
    _id: "1",
    title: "Sajar Stone",
    icon: icon1,
    text: "Sajar stone art is a unique form of art that originates from the Bundelkhand region of India. It involves the carving of intricate designs and patterns on stones, usually using traditional tools and techniques. The art form has been practiced in the region for centuries and is highly valued for its aesthetic appeal and cultural significance. Many skilled artisans in the Bundelkhand region continue to practice and preserve this art form to this day.",
  },
  {
    _id: "2",
    title: "Wooden carvings",
    icon: icon2,
    text: "The artisans of Bundelkhand create intricate wooden carvings, furniture, toys, and utensils using locally sourced wood. The craft is deeply rooted in the region's history and has been passed down from generation to generation. The unique designs and high-quality craftsmanship of Bundelkhand's wooden craft have earned it a reputation across the country and the world.",
  },
  {
    _id: "3",
    title: "Block painting",
    icon: icon3,
    text: "Block painting, also known as 'Chattisgarh painting', is a traditional art form that originated in the Bundelkhand region of Central India. This style of painting is characterized by the use of bright colors and intricate geometric patterns to create beautiful designs on cloth, walls, and other surfaces. The art form has been practiced in the region for centuries, and is still popular today as a way of preserving local cultural traditions",
  },
];

function BandaSection() {
  const [scroll, setScroll] = useState(false);
  const [display, setDisplay] = useState({
    _id: "0",
    title: "Banda",
    text: "Banda is a city and a municipal board in Banda district in the state of Uttar Pradesh, India. Banda is divided between north, east, west and south Banda. Banda lies south of the Yamuna river in the Bundelkhand region. It is the administrative headquarters of Banda District.",
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1350);
    });
  }, []);

  return (
    <div
      className={
        scroll ? "bandaDisctrictContainer visible" : "bandaDisctrictContainer"
      }
    >
      <div className="left">
        <div className="subtitle">Disctrict</div>
        <div className="title">
          <h2>{display?.title}</h2>
        </div>
        <div className="description">
          <p>{display?.text}</p>
        </div>
      </div>
      <div className="right">
        {arts?.map((item) => {
          return (
            <div
              className="disctrict-handicraft-card"
              onMouseOver={() => setDisplay(item)}
              onMouseOut={() =>
                setDisplay({
                  _id: "0",
                  title: "Banda",
                  text: "Banda is a city and a municipal board in Banda district in the state of Uttar Pradesh, India. Banda is divided between north, east, west and south Banda. Banda lies south of the Yamuna river in the Bundelkhand region. It is the administrative headquarters of Banda District.",
                })
              }
            >
              <div className="craft-logo">
                <img src={item.icon} alt="woodentoy" />
              </div>
              <div className="craft-name">
                <p>{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BandaSection;
