import React from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";

const ProductCard = ({ product }) => {
  return (
    <Link
      className="productCard"
      // to={`/product/${product?.groupId}`}
      // state={{ Data: product }}
    >
      <img
        src={product.image}
        alt={product.name}
        style={{ height: "200px", width: "200px", objectFit: "contain" }}
      />
      {product?.discount > 0 && <div className="deal-container">ON SALE</div>}
      <p>{product.name}</p>

      <span
        style={{
          fontSize: "22px",
        }}
      >
        ₹ {`${product.price}`}
      </span>

      {/* <Button
        style={{
          position: "absolute",
          bottom: "2px",
          marginTop: "120px",
          backgroundColor: "teal",
          color: "white",
        }}
        onClick={handleSubmit}
      >
        Add to Cart
      </Button> */}
    </Link>
  );
};

export default ProductCard;
