import React, { useEffect, useState } from "react";
import "./MahobaSection.css";
import icon1 from "../../assets/logo/kalakaricon.png";
import icon2 from "../../assets/logo/metalWork.png";

const arts = [
  {
    _id: "1",
    title: "Embroidery",
    icon: icon1,
    text: "Embroidery is a popular craft in Mahoba Bundelkhand, with skilled artisans creating intricate designs on fabrics using colorful threads. The embroidery work is often inspired by local flora and fauna, as well as traditional designs and patterns. The embroidered fabrics are used to create beautiful garments, accessories, and home decor items. The embroidery work is a testament to the creativity and skill of the local craftsmen, and visitors can witness the beauty of this craft by exploring local markets and shops.",
  },
  {
    _id: "2",
    title: "Metal Crafts",
    icon: icon2,
    text: "Mahoba Bundelkhand is famous for its metal crafts, which are an important part of the region's cultural heritage. Artisans use a variety of metals, including brass, bronze, and copper, to create intricate sculptures, utensils, and other decorative items. These metal crafts are often adorned with intricate designs and patterns, showcasing the skill and creativity of the craftsmen. Visitors to Mahoba Bundelkhand can explore the local bazaars and witness the beauty of these metal crafts firsthand.",
  },
];

function MahobaSection() {
  const [scroll, setScroll] = useState(false);
  const [display, setDisplay] = useState({
    _id: "0",
    title: "Mahoba",
    text: "Mahoba is a city in Mahoba District of the Indian state of Uttar Pradesh in the Bundelkhand region, well known for the ninth century granite Sun temple built in Pratihara style. It is also well known for the 24 rock-cut Jain tirthankara image on Gokhar hill",
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 4100);
    });
  }, []);

  return (
    <div
      className={
        scroll ? "mahobaDisctrictContainer visible" : "mahobaDisctrictContainer"
      }
    >
      <div className="left">
        <div className="subtitle">Disctrict</div>
        <div className="title">
          <h2>{display?.title}</h2>
        </div>
        <div className="description">
          <p>{display?.text}</p>
        </div>
      </div>
      <div className="right">
        {arts?.map((item) => {
          return (
            <div
              className="disctrict-handicraft-card"
              onMouseOver={() => setDisplay(item)}
              onMouseOut={() =>
                setDisplay({
                  _id: "0",
                  title: "Mahoba",
                  text: "Mahoba is a city in Mahoba District of the Indian state of Uttar Pradesh in the Bundelkhand region, well known for the ninth century granite Sun temple built in Pratihara style. It is also well known for the 24 rock-cut Jain tirthankara image on Gokhar hill",
                })
              }
            >
              <div className="craft-logo">
                <img src={item.icon} alt="woodentoy" />
              </div>
              <div className="craft-name">
                <p>{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MahobaSection;
