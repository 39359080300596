import React, { useEffect, useState } from "react";
import "./DetailPage.css";
import VideoBanner from "./VideoBanner";
import HorizontalCarousel from "./../ImageGallery/HorizontalCarousel";
import Slider from "./../Banner/Slider";
import img1 from "../../assets/lalitpur/photo_6122762114704716588_y.jpg";
import img2 from "../../assets/lalitpur/photo_6122762114704716589_y.jpg";
import img3 from "../../assets/lalitpur/photo_6122762114704716590_y.jpg";

import chitrakootBanner1 from "../../banners/lalitpur/lalitpur1.png";
import chitrakootBanner2 from "../../banners/lalitpur/lalitpur2.png";
import DetailPageSlider from "../Banner/DetailPageSlider";

const images = [img1, img2, img3];

const bannerImages = [
  {
    _id: "1",
    img: chitrakootBanner1,
  },
  {
    _id: "2",
    img: chitrakootBanner2,
  },
];

function DetailPageLalitpur() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="detailContainer">
      <div className="aboutSection">
        <section>
          <ul>
            <li>
              <a href="">Home</a>
            </li>
            <li></li>
            <li>
              <a href="">Detail</a>
            </li>
          </ul>
          <h1>One Day Seminar - Lalitpur</h1>
        </section>
      </div>
      <DetailPageSlider images={bannerImages} />
      <div className="ImageHeading">
        <h2>Image Gallery</h2>
      </div>
      <HorizontalCarousel images={images} />
      <div className="row">
        <div className="left">
          <div className="contentWrapper">
            <div className="title">
              <h2>Mega Cluster</h2>
            </div>
            <div className="description">
              <p>
                Direct marketing opportunities for Bundelkhand artisans can
                include: Online marketplaces: There are various online
                marketplaces like Amazon, Flipkart, and Etsy where artisans can
                showcase and sell their products directly to customers. They can
                also create their own website or social media pages to promote
                their products. Craft fairs and exhibitions: Artisans can
                participate in local and regional craft fairs and exhibitions to
                showcase and sell their products. This allows them to directly
                interact with potential customers and build brand awareness.
                Collaborations with retailers: Artisans can collaborate with
                retailers to sell their products in their stores. This can be
                done through consignment or wholesale arrangements.
              </p>
              <br />
              <p>
                Corporate gifting: Artisans can explore opportunities for
                corporate gifting by reaching out to companies and offering
                their handmade products as gifts for employees and clients.
                Direct mail campaigns: Artisans can use direct mail campaigns to
                reach potential customers directly. This can include sending
                product catalogs, brochures, or promotional offers to a targeted
                list of potential customers. Referral marketing: Artisans can
                encourage their existing customers to refer their products to
                their friends and family. This can be done through referral
                programs or by offering discounts or other incentives for
                referrals. Local partnerships: Artisans can form partnerships
                with local businesses, such as hotels and restaurants, to sell
                their products. This can provide a steady stream of customers
                and help build a local customer base. Overall, there are many
                direct marketing opportunities available for Bundelkhand
                artisans. By taking advantage of these opportunities, artisans
                can build brand awareness, reach new customers, and increase
                sales of their handmade products.
              </p>
            </div>
          </div>
        </div>
      </div>

      <VideoBanner video={"https://www.youtube.com/embed/romJzJ6t0Ms"} />
    </div>
  );
}

export default DetailPageLalitpur;
