import React, { useEffect, useState } from "react";
import "./JhansiSection.css";
import icon1 from "../../assets/logo/kalakaricon.png";
import icon2 from "../../assets/logo/blockprinting.png";
import icon3 from "../../assets/logo/wt1.png";

const arts = [
  {
    _id: "1",
    title: "Kalamkari ",
    icon: icon1,
    text: "Kalamkari is a traditional art form of hand printing textiles that has been practiced in Jhansi for centuries. The process involves the use of natural dyes and intricate hand-carved wooden blocks to create vibrant and intricate designs on fabrics such as cotton and silk. Kalankari is not only a means of livelihood for many artisans in Jhansi, but also a treasured cultural heritage of the region",
  },
  {
    _id: "2",
    title: "Block Printing ",
    icon: icon2,
    text: "Block printing is one such art form that has been practiced in Jhansi for centuries. The technique involves carving intricate designs onto wooden blocks, which are then used to stamp the designs onto fabric or paper. Jhansi block printing is renowned for its vibrant colors and bold motifs, and is often used to create beautiful sarees, bedspreads, and other textiles.",
  },
  {
    _id: "3",
    title: "Toy Making",
    icon: icon3,
    text: "The toys are made from natural materials like wood, bamboo, and clay, and are often inspired by local flora and fauna. The artisans in Jhansi create a wide variety of toys, including dolls, puppets, and animals, which are popular with both children and collectors. Toy making is an important part of the local economy and has been practiced in Jhansi for generations.",
  },
];

function JhansiSection() {
  const [scroll, setScroll] = useState(false);
  const [display, setDisplay] = useState({
    _id: "0",
    title: "Jhansi",
    text: "Jhansi is a historic city in the Indian state of Uttar Pradesh. Balwant Nagar was the old name of Jhansi. It lies in the region of Bundelkhand on the banks of the Pahuj River, in the extreme south of Uttar Pradesh. Jhansi is the administrative headquarters of Jhansi district and Jhansi division.",
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1950);
    });
  }, []);

  return (
    <div
      className={
        scroll ? "jhansiDisctrictContainer visible" : "jhansiDisctrictContainer"
      }
    >
      <div className="right">
        {arts?.map((item) => {
          return (
            <div
              className="disctrict-handicraft-card"
              onMouseOver={() => setDisplay(item)}
              onMouseOut={() =>
                setDisplay({
                  _id: "0",
                  title: "Jhansi",
                  text: "Jhansi is a historic city in the Indian state of Uttar Pradesh. Balwant Nagar was the old name of Jhansi. It lies in the region of Bundelkhand on the banks of the Pahuj River, in the extreme south of Uttar Pradesh. Jhansi is the administrative headquarters of Jhansi district and Jhansi division.",
                })
              }
            >
              <div className="craft-logo">
                <img src={item.icon} alt="woodentoy" />
              </div>
              <div className="craft-name">
                <p>{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="left">
        <div className="subtitle">Disctrict</div>
        <div className="title">
          <h2>{display?.title}</h2>
        </div>
        <div className="description">
          <p>{display?.text}</p>
        </div>
      </div>
    </div>
  );
}

export default JhansiSection;
