import React, { useEffect, useState } from "react";
import "./DetailPage.css";
import VideoBanner from "./VideoBanner";
import HorizontalCarousel from "../ImageGallery/HorizontalCarousel";
import Slider from "../Banner/Slider";
import img1 from "../../assets/thematicbanda/photo_6122762114704716591_y.jpg";
import img2 from "../../assets/thematicbanda/photo_6122762114704716592_y.jpg";
import img3 from "../../assets/thematicbanda/photo_6122762114704716593_y.jpg";
import img4 from "../../assets/thematicbanda/photo_6122762114704716594_y.jpg";
import img5 from "../../assets/thematicbanda/photo_6122762114704716595_y.jpg";
import img6 from "../../assets/thematicbanda/photo_6122762114704716596_y.jpg";
import img7 from "../../assets/thematicbanda/photo_6122762114704716597_y.jpg";
import img8 from "../../assets/thematicbanda/photo_6122762114704716598_y.jpg";

import jhansi1 from "../../banners/thematicbanda/thjh1.jpg";
import jhansi2 from "../../banners/thematicbanda/thjh2.png";
import jhansi3 from "../../banners/thematicbanda/thjh3.png";
import DetailPageSlider from "../Banner/DetailPageSlider";

const images = [img1, img2, img3, img4, img5, img6, img7, img8];

const bannerImages = [
  {
    _id: "1",
    img: jhansi1,
  },
  {
    _id: "2",
    img: jhansi2,
  },
  {
    _id: "3",
    img: jhansi3,
  },
];

function ThematicExhibitionBanda() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="detailContainer">
      <div className="aboutSection">
        <section>
          <ul>
            <li>
              <a href="">Home</a>
            </li>
            <li></li>
            <li>
              <a href="">Detail</a>
            </li>
          </ul>
          <h1>Thematic Exhibition - Banda</h1>
        </section>
      </div>
      <DetailPageSlider images={bannerImages} />

      <div className="row">
        <div className="left">
          <div className="contentWrapper">
            <div className="title">
              <h2>Thematic Exhibition</h2>
            </div>
            <div className="description">
              <p>
                The term 'thematic exhibition'/ 'ahistoric' has been used to
                describe a new type of exhibition where images and objects are
                displayed independently of traditional chronological
                arrangement. The aim of this style is to reveal correspondences
                between works from what might be very distant periods and
                cultures. These affinities cut across chronological boundaries
                and the conventional stylistic categories implemented in art
                history. The classical classification or material is abandoned
                as well. banda thematic exhibition is assemblage of
                exiquisitive art and resemblance of culture through-
              </p>
              <br />
              <p>
<li>Handmade carpets - This is prominent woven , hand-knotted
                carpet. It is manufactured on a vertical wooden loom. Thick
                cotton and woollen yarns are used in the warp ends while wool is
                used in the width direction. These looms are equipped with a
                double layer of warp ends separator rods and platforms. Designs
                depicting flowers, animals, gardens, trees and trellises are
                used in various hues and ways to liven up these floor coverings</li>
                <li>
                Terracota- Terracotta occupies an important place in the
                golden pages of the history. The items that are made of
                terracotta have clay don brownish orange color. These objects
                have a very earthen look and it is this feel that attracts
                travelers from far and woo them to splurge money to buy such
                aesthetic pecies of art.</li>
                <li> Wooden toys- Wooden toy-making is a
                traditional craft in Bundelkhand . Bright and colourful
                lacquered toys are made by clusters of skilled craftsmen. The
                wood from eucalyptus is used predominantly. Pieces of wood are
                cut Each piece is heated. Then piece is sanded. Wood is either
                hand-carved or shaped using lathe. Once shaped, the surface of
                the toy is smoothened and the toy is sent for painting. Paint
                brushes made out of the hair from squirrel’s tail are used for
                painting fine lines.</li><li>
                Kalamkari - Kalamkari when segregated is a beautiful amalgamation of two words  where kalam means  pen and Kari stands for craftsmanship.
It is known to be a healing fabric, as the colors, motifs and the narration of magical forms create an aura that heals an individual physically and spiritually. Only natural dyes are used in Kalamkari, which is an ancient style of hand painting done on cotton or silk fabric with a tamarind pen.
Thus this exceptional piece of art is exhibited through a time consuming process by skilled craftsmen of India.
                </li>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ImageHeading">
        <h2>Image Gallery</h2>
      </div>
      <HorizontalCarousel images={images} />
      <VideoBanner video={"https://www.youtube.com/embed/yfMxK3WK8gw"} />
    </div>
  );
}

export default ThematicExhibitionBanda;
