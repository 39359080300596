import React, { useEffect, useState } from "react";
import "./ChitrakootSection.css";
import icon1 from "../../assets/logo/wt2.png";
import icon2 from "../../assets/logo/terracotaicon.png";
import icon3 from "../../assets/logo/embroideryicon.png";

const arts = [
  {
    _id: "1",
    title: "Wooden Toy",
    icon: icon1,
    text: "In the Bundelkhand region, wooden toys are a popular handicraft. Artisans create intricate and colorful toys using locally sourced wood. The toys range from small animals to dolls and puzzles, and are often painted with bright colors and designs. These wooden toys are not only popular among children but are also considered a traditional form of art in the region. They make for great souvenirs and gifts for tourists visiting Bundelkhand",
  },
  {
    _id: "2",
    title: "Teracotta",
    icon: icon2,
    text: "Terracotta is a form of pottery made from clay that has been fired at low temperatures. Terracotta has been a popular art form for centuries. Local artisans create a variety of terracotta items, such as pots, figurines, and decorative pieces, using traditional techniques passed down through generations. These pieces are often adorned with intricate designs and motifs, reflecting the rich cultural heritage of Bundelkhand",
  },
  {
    _id: "3",
    title: "Embroidery",
    icon: icon3,
    text: "Chitrakoot, a town in the state of Uttar Pradesh in India, is known for its intricate embroidery work. The embroidery of Chitrakoot is famous for its detailed needlework and vibrant colors. The embroidery designs often feature traditional motifs such as flowers, birds, and paisleys. The artisans in Chitrakoot use a variety of stitches such as chain stitch, satin stitch, and running stitch to create their beautiful designs. The embroidery work is usually done on fabrics such as cotton, silk, and chiffon, and the finished products include sarees, dupattas, and cushion covers, among others. The Chitrakoot embroidery is a testament to the skill and creativity of the local artisans and is a source of pride for the town.",
  },
];

function ChitrakootSection() {
  const [scroll, setScroll] = useState(false);
  const [display, setDisplay] = useState({
    _id: "0",
    title: "Chitrakoot",
    text: "Chitrakoot is a town located in the northern Indian state of Uttar Pradesh. It is situated on the banks of the Mandakini River and is known for its religious and cultural significance.In Hindu mythology, Chitrakoot is believed to be the place where Lord Rama, his wife Sita, and his brother Lakshmana spent a significant part of their 14-year exile. The town is also associated with the sage Valmiki, who is said to have composed the epic Ramayana here.",
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 950);
    });
  }, []);

  return (
    <div
      className={
        scroll
          ? "chitrakootDisctrictContainer visible"
          : "chitrakootDisctrictContainer"
      }
    >
      <div className="right">
        {arts?.map((item) => {
          return (
            <div
              className="disctrict-handicraft-card"
              onMouseOver={() => setDisplay(item)}
              onMouseOut={() =>
                setDisplay({
                  _id: "0",
                  title: "Chitrakoot",
                  text: "Chitrakoot is a town located in the northern Indian state of Uttar Pradesh. It is situated on the banks of the Mandakini River and is known for its religious and cultural significance.In Hindu mythology, Chitrakoot is believed to be the place where Lord Rama, his wife Sita, and his brother Lakshmana spent a significant part of their 14-year exile. The town is also associated with the sage Valmiki, who is said to have composed the epic Ramayana here.",
                })
              }
            >
              <div className="craft-logo">
                <img src={item.icon} alt="woodentoy" />
              </div>
              <div className="craft-name">
                <p>{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="left">
        <div className="subtitle">Disctrict</div>
        <div className="title">
          <h2>{display?.title}</h2>
        </div>
        <div className="description">
          <p>{display?.text}</p>
        </div>
      </div>
    </div>
  );
}

export default ChitrakootSection;
