import React from "react";
import "./ContactUs.css";
import Form from "./../../components/Form/Form";

function ContactUs() {
  return (
    <div className="contactusContainer">
      <div className="heading">
        <h1>Contact Us</h1>
        <div className="line" />
        <p>We're are open for any suggestions or just to have a chat</p>
        <p>Email: info@bundelkhandmegacluster.com</p>
      </div>
      <Form />
    </div>
  );
}

export default ContactUs;
