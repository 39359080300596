import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { mobile } from "./../../responsive";
import cmBanner from "../../banners/yogi.png";
import pmBanner from "../../banners/modi.png";
import banda from "../../banners/homepage/banda.png";
import chitrakoot from "../../banners/homepage/chitrakoot.png";
import hamirpur from "../../banners/homepage/hamirpur.png";
import jalaun from "../../banners/homepage/jalaun.png";
import jhansi from "../../banners/homepage/jhansi.png";
import lalitpur from "../../banners/homepage/lalitpur.png";
import mahoba from "../../banners/homepage/mahoba.png";

const Slider = () => {
  const [sliderItems, setSliderItems] = useState([
    {
      _id: "1",
      img: pmBanner,
    },
    {
      _id: "2",
      img: cmBanner,
    },
    {
      _id: "3",
      img: banda,
    },
    {
      _id: "4",
      img: chitrakoot,
    },
    {
      _id: "5",
      img: hamirpur,
    },
    {
      _id: "6",
      img: jalaun,
    },
    {
      _id: "7",
      img: jhansi,
    },
    {
      _id: "8",
      img: lalitpur,
    },
    {
      _id: "9",
      img: mahoba,
    },
  ]);
  const [index, setIndex] = useState(0);

  const handleClick = (direction) => {
    if (direction === "left") {
      setIndex(index > 0 ? index - 1 : sliderItems?.length - 1);
    } else {
      setIndex(index < sliderItems?.length - 1 ? index + 1 : 0);
    }
  };

  const caraousel = () => {
    if (index === sliderItems?.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      caraousel();
    }, 6000);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </Arrow>
      <Wrapper index={index}>
        {sliderItems?.map((item) => (
          <Slide
            bg={item.bg}
            key={item._id}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            onMouseOver={() => {
              // setIndex(0);
            }}
          >
            <ImgContainer>
              <Image src={item.img} />
            </ImgContainer>
          </Slide>
        ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1310px;
  height: 55vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ height: "200px" })}
  margin: auto;
  box-shadow: 0 2px 13px 0 rgba(12, 33, 67, 0.18);
  border-radius: 5px;
  margin-top: 130px;
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 55vh;
  ${mobile({ height: "200px" })}
  background-color: #${(props) => props.bg};
  transition: ease 2000ms;
`;

const ImgContainer = styled.div`
  height: 100%;
  ${mobile({ display: "contain" })}
  flex: 1;
`;

const Image = styled.img`
  height: 100%;
  width: 1310px;
  ${mobile({ width: "100%", height: "200px" })}
`;

export default Slider;
