import React, { useEffect, useState } from "react";
import "./HamirpurSection.css";
import icon1 from "../../assets/logo/metalWork.png";
import icon2 from "../../assets/logo/silverFish.png";

const arts = [
  {
    _id: "1",
    title: "Metal Crafts",
    icon: icon1,
    text: "The metal craft industry in Hamirpur specializes in creating exquisite handcrafted metal products using traditional techniques. The artisans here use materials such as brass, copper, and silver to make items like utensils, decorative pieces, and jewelry. These crafts not only showcase the unique creativity and skill of the local artisans but also provide an important source of livelihood for many families in the region.",
  },
  {
    _id: "2",
    title: "Silver Fish",
    icon: icon2,
    text: "Hamirpur Bundelkhand is renowned for its skilled craftsmen who create exquisite silver artwork, including fish figurines. The artists use traditional techniques to shape and mold the silver into intricate designs, capturing the fluid movement and beauty of fish. These silver fish figurines are often used as decorative pieces, gifts, or even as part of religious ceremonies. The silver fish artwork is a true testament to the region's rich cultural heritage and the skill and dedication of its artisans",
  },
];

function HamirpurSection() {
  const [scroll, setScroll] = useState(false);
  const [display, setDisplay] = useState({
    _id: "0",
    title: "Hamirpur",
    text: "Hamirpur is a city and a municipal board in Hamirpur district in the Indian state of Uttar Pradesh. It is the administrative headquarters of Hamirpur district",
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 3550);
    });
  }, []);

  return (
    <div
      className={
        scroll
          ? "hamirpurDisctrictContainer visible"
          : "hamirpurDisctrictContainer"
      }
    >
      <div className="right">
        {arts?.map((item) => {
          return (
            <div
              className="disctrict-handicraft-card"
              onMouseOver={() => setDisplay(item)}
              onMouseOut={() =>
                setDisplay({
                  _id: "0",
                  title: "Hamirpur",
                  text: "Hamirpur is a city and a municipal board in Hamirpur district in the Indian state of Uttar Pradesh. It is the administrative headquarters of Hamirpur district",
                })
              }
            >
              <div className="craft-logo">
                <img src={item.icon} alt="woodentoy" />
              </div>
              <div className="craft-name">
                <p>{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="left">
        <div className="subtitle">Disctrict</div>
        <div className="title">
          <h2>{display?.title}</h2>
        </div>
        <div className="description">
          <p>{display?.text}</p>
        </div>
      </div>
    </div>
  );
}

export default HamirpurSection;
