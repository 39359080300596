import React, { useEffect, useState } from "react";
import "./AboutUs.css";

function AboutUs() {
  const [summary, setSummary] = useState(false);
  const [objective, setObjective] = useState(false);
  const [advantage, setAdvantage] = useState(false);

  useEffect(() => {
    setSummary(true);
  }, []);

  return (
    <div className="categoryPageContainer">
      <div className="mainSection">
        <div className="containers">
          <ul class="nav nav-tabs" role="tablist">
            <li
              class="nav-item"
              onClick={() => {
                setSummary(true);
                setObjective(false);
                setAdvantage(false);
              }}
            >
              <a class="nav-link active" data-toggle="tab" href="#summary">
                <span>SUMMARY</span>
              </a>
            </li>

            <li
              class="nav-item"
              onClick={() => {
                setSummary(false);
                setObjective(true);
                setAdvantage(false);
              }}
            >
              <a class="nav-link" data-toggle="tab" href="#objectives">
                <span>OBJECTIVES</span>
              </a>
            </li>
            <li
              class="nav-item"
              onClick={() => {
                setSummary(false);
                setObjective(false);
                setAdvantage(true);
              }}
            >
              <a class="nav-link" data-toggle="tab" href="#cuisine">
                <span>ADVANTAGES</span>
              </a>
            </li>
          </ul>
          <div className="content">
            <div className="cards">
              <div className="left">
                <div
                  className={summary ? "leftContent visible" : "leftContent"}
                >
                  <div class="leftContentdetails">
                    <h3>Summary</h3>
                    <p>
                      Bundelkhand MegaCluster under CHCDS is intended to scale
                      up the infrastructural and production chain at Handicrafts
                      clusters in Bundelkand region particularly in Jhansi,
                      Chitrakoot , Jalaun , Hamirpur, Mahoba , Lalitpur, Banda
                      which have remained unorganized and have not kept pace
                      with the modernization and development that have been
                      taking place. Handicrafts being the backbone of long
                      traditional heritage and cultural linkages.
                      <br />
                      <br />
                      Mega Cluster Scheme will focus on infrastructural
                      improvement, modernization of the tools, machinery,
                      process, product development & diversification and
                      creating strong brands, Innovative designs, technical
                      know-how, furthered by brand building of the native
                      products hold the key to creating a niche market for the
                      products manufactured by the clusters.
                      <br />
                      <br />
                      Mega Cluster Scheme is expected to support the
                      Upgradation/creation of infrastructural facilities coupled
                      with market linkages and product development &
                      diversification.
                    </p>
                  </div>
                </div>
                <div
                  className={objective ? "leftContent visible" : "leftContent"}
                >
                  <div class="leftContentdetails">
                    <h3>Objectives</h3>
                    <p>
                      Bundelkhand Megacluster will focus on below mentioned
                      objectives
                      <br />
                      <br />
                      i. To enhance the competitiveness of selected clusters in
                      terms of increased market share and ensuring increased
                      productivity by higher unit value realization of the
                      products.
                      <br />
                      <br />
                      ii. To ensure effective integration of scattered artisans,
                      building their grass roots enterprises and linking them to
                      SMEs in the sector to build critical mass for customized
                      interventions and ensure economies of scale in operations.
                      This will build a supply system that is geared to
                      responding to large-scale orders, adhering to quality and
                      product standardization, which are pre-requisites of
                      global markets.
                      <br />
                      <br />
                      iii. To generate additional livelihood opportunities to
                      the people through specific intervention in segmental sub
                      sector industry and increase the incomes to the
                      artisans/craftsmen already engaged in this sector.
                      <br />
                      <br />
                      iv. To provide requisite support/ linkages in terms of
                      adequate infrastructure, technology, product
                      diversification, design development, raw material banks,
                      marketing & promotion, social security and other
                      components that are vital for sustainability of
                      artisans/craftsmen engaged in the Handicrafts sector.
                      <br />
                      <br />
                      v. The core elements of the strategy for the proposed
                      program are given below: vi. Proactive and strong
                      technical and program management assistance for capacity
                      building, designing of the interventions and their
                      implementation, through a competent professional agency.
                    </p>
                  </div>
                </div>
                <div
                  className={advantage ? "leftContent visible" : "leftContent"}
                >
                  <div class="leftContentdetails">
                    <h3>Advantages</h3>
                    <p>
                      Social:
                      <br />
                      <br />
                      i. Employment Generation
                      <br />
                      <br />
                      ii. Better living standards for the existing artisans
                      <br />
                      <br />
                      Economic:
                      <br />
                      <br />
                      i. Foreign Exchange earnings by export
                      <br />
                      <br />
                      ii. Substantial Increase in quality and value added
                      Production
                      <br />
                      <br />
                      iii. Increase in the business of small entrepreneurs iv.
                      Savings in cost by manufacturers in the cluster due to
                      better infrastructure and Government induced benefits v.
                      Revenue generation to local bodies and State & Central
                      Governments
                      <br />
                      <br />
                      vi. Growth of industry in an organized form
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="monument">&nbsp;</div>
      </div>
    </div>
  );
}

export default AboutUs;
