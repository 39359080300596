import { useEffect, useState } from "react";
import styled from "styled-components";
import apiClient from "../../api/client";
import { mobile } from "./../../responsive";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";

const VideoBanner = ({video}) => {
  console.log(video)
  const [videos, setVideos] = useState([
    {
      _id: "1",
      videoLink: "https://www.youtube.com/embed/FprZdlXSF9s",
    },
    {
      _id: "2",
      videoLink: "https://www.youtube.com/embed/9VXsoz4X2Ok",
    },
    {
      _id: "3",
      videoLink: "https://www.youtube.com/embed/WMfLq-I7J7U",
    },
  ]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [index, setIndex] = useState(0);

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : videos?.length);
    } else {
      setSlideIndex(slideIndex < videos?.length ? slideIndex + 1 : 0);
    }
  };
  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    const { data } = await apiClient.get("/variations/videos-banner");
    // setVideos(data);
  };

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </Arrow>

      <Wrapper slideIndex={slideIndex}>
        {videos?.map((item) => (
          <Slide
            bg={item.bg}
            key={item._id}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            className="video-bg"
          >
            <iframe width="80%" height="500" src={video}></iframe>
          </Slide>
        ))}
      </Wrapper>

      <Wrapper slideIndex={slideIndex}>
        {videos?.map((item) => (
          <Slide
            bg={item.bg}
            key={item._id}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            className="video-bg"
          >
            <iframe width="80%" height="500" src={video}></iframe>
          </Slide>
       ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  overflow: hidden;
  margin: auto;
  ${mobile({ height: "200px" })}
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #${(props) => props.bg};
  transition: ease 2000ms;
`;

export default VideoBanner;
