import React, { useEffect, useState } from "react";
import "./Navbar.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Logo from "../../assets/logo/dchandicraftLogo.png";
import webLogo from "../../assets/logo/webLogo.png";
import { useNavigate } from "react-router-dom";
import g20 from "../../assets/g20.png";
import azadi from "../../assets/azadi.png";

const menu = [
  {
    _id: 1,
    name: "Setting up CFC",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 2,
    name: "Setting up Raw Material Bank",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 3,
    name: "Baseline Survey",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 4,
    name: "Guru Shishya Hastshilp Prashikshan Program",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 5,
    name: "Design and Technical Development Workshop",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 6,
    name: "Toolkit Distribution",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 7,
    name: "Producer Group Companies Formation",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 8,
    name: "One Day Seminar",
    cities: [
      { name: "Banda", link: "/detail/banda" },
      { name: "Chitrakoot", link: "/detail/chitrakoot" },
      { name: "Jhansi", link: "/detail/jhansi" },
      { name: "Jalaun", link: "/detail/jalaun" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail/mahoba" },
      { name: "Lalitpur", link: "/detail/lalitpur" },
    ],
  },
  {
    _id: 9,
    name: "Thematic Exhibition",
    cities: [
      { name: "Banda", link: "/thematic-exhibition/banda" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
  {
    _id: 10,
    name: "Gandhi Shilp Bazaar",
    cities: [
      { name: "Banda", link: "/detail" },
      { name: "Chitrakoot", link: "/detail" },
      { name: "Jhansi", link: "/detail" },
      { name: "Jalaun", link: "/detail" },
      { name: "Hamirpur", link: "/detail" },
      { name: "Mahoba", link: "/detail" },
      { name: "Lalitpur", link: "/detail" },
    ],
  },
];

const cities = [
  { name: "Banda", link: "/banda" },
  { name: "Chitrakoot", link: "/chitrakoot" },
  { name: "Jhansi", link: "/jhansi" },
  { name: "Jalaun", link: "/jalaun" },
  { name: "Hamirpur", link: "/hamirpur" },
  { name: "Mahoba", link: "/mahoba" },
  { name: "Lalitpur", link: "/coming-soon" },
];

function Navbar() {
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [mobileHeading, setMobileHeading] = useState();

  const [subcategory, setSubcategory] = useState([]);

  const [subcatList, setSubCatList] = useState();

  const [back, setback] = useState(false);
  const [list, setList] = useState();
  const [list1, setList1] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setAnimate(false);
    setMobileHeading("MENU");
    setList1([
      {
        _id: 1,
        name: "HOME",
        link: "/",
      },
      {
        _id: 2,
        name: "PROGRAMS",
        link: "/",
      },
      {
        _id: 3,
        name: "ABOUT US",
        link: "/aboutus",
      },
      {
        _id: 4,
        name: "DISTRICTS",
        link: "/coming-soon",
      },
      {
        _id: 5,
        name: "GALLERY",
        link: "/coming-soon",
      },
      {
        _id: 6,
        name: "SHOP",
        link: "/coming-soon",
      },
      {
        _id: 7,
        name: "CONTACT US",
        link: "/contactus",
      },
    ]);
    setList([
      {
        _id: 1,
        name: "Setting up CFC",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 2,
        name: "Setting up Raw Material Bank",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 3,
        name: "Baseline Survey",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 4,
        name: "Guru Shishya Hastshilp Prashikshan Program",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 5,
        name: "Design and Technical Development Workshop",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 6,
        name: "Toolkit Distribution",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 7,
        name: "Producer Group Companies Formation",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 8,
        name: "One Day Seminar",
        cities: [
          { name: "Banda", link: "/detail/banda" },
          { name: "Chitrakoot", link: "/detail/chitrakoot" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail/mahoba" },
        ],
      },
      {
        _id: 9,
        name: "Thematic Exhibition",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
      {
        _id: 10,
        name: "Gandhi Shilp Bazaar",
        cities: [
          { name: "Banda", link: "/detail" },
          { name: "Chitrakoot", link: "/detail" },
          { name: "Jhansi", link: "/detail" },
          { name: "Jalaun", link: "/detail" },
          { name: "Hamirpur", link: "/detail" },
          { name: "Mahoba", link: "/detail" },
        ],
      },
    ]);
  }, [back, modal]);

  const handleItemDesktop = (item) => {
    setModal3(true);
    setMobileHeading(item.name);
    setSubCatList(item.cities);
  };

  const handleItemMobile = (item) => {
    if (item?._id !== 2 && item.link) {
      navigate(`${item.link}`);
      setModal(!modal);
    } else if (item?.name === "PROGRAMS") {
      setMobileHeading(item.name);
      setList1(list);
    } else {
      setAnimate(false);
      setAnimate(true);
      if (item.name && !item.link) {
        setList1(item.cities);
      } else if (item.name && item.link) {
        navigate(`${item.link}`);
        setModal(!modal);
      }
      setMobileHeading(item.name);
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <div className="navbarContainer">
      <div className="headerTopSection">
        <div className="containers">
          <div className="leftPanel">
            <ul>
              <li>
                <a href="/" target="_blank" rel="nofollow">
                  <img
                    alt="GOI"
                    title="GOI"
                    loading="lazy"
                    width="201"
                    height="50"
                    src={Logo}
                  />
                </a>
              </li>

              <li>
                <div class="follow-wrapp">
                  <a
                    href="https://www.facebook.com/profile.php?id=100089547992136"
                    target="_blanck"
                    class="fb"
                  >
                    <i class="fa fa-facebook " aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://twitter.com/Bundelkhandmega?t=BxToWF1kVqF7VdB9Y-Uj3g&s=09"
                    target="_blanck"
                    class="twitters"
                  >
                    <i class="fa fa-twitter itwitter" aria-hidden="true"></i>
                  </a>

                  <a
                    href="https://instagram.com/bundelkhandmegacluster?igshid=ZDdkNTZiNTM="
                    target="_blanck"
                    class="instagram"
                  >
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@bundelkhnadmegacluster"
                    target="_blanck"
                    class="youtube"
                  >
                    <i class="fa fa-youtube" aria-hidden="true"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="rightPanel">
            <nav className="navbar menus">
              {/* <ul className="header-top-menu">
                <li className="list-item menu-dropdown">
                  <a href="/">Home</a>
                </li>
                <li className="list-item menu-dropdown">
                  <a href="/coming-soon">About</a>
                </li>
                <li className="list-item menu-dropdown">
                  <a href="/coming-soon" data-drupal-link-system-path="faqs">
                    FAQs
                  </a>
                </li>
                <li className="list-item menu-dropdown">
                  <a href="/coming-soon">Guide</a>
                </li>
                <li className="list-item menu-dropdown">
                  <a href="/coming-soon">Contact</a>
                </li>
              </ul> */}
              <ul class="font-lang">
                <li>
                  <div class="language" tabindex="0">
                    ENG
                  </div>
                  <div class="lang-hover">
                    <p>Other Languages Coming Soon</p>
                    <div class="lang-arrow"></div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="headerMainSection">
        <div className="headerSection">
          <a className="logo" href="/">
            <img src={webLogo} alt="bundelkhandlogo" className="web-logo" />
          </a>
          <div className="navbar">
            <ul className="header-st-menu">
              <li tabindex="0">
                <a href="/">
                  <div>
                    <div>
                      <h4>HOME</h4>
                    </div>
                  </div>
                </a>
              </li>
              <li
                tabindex="0"
                onMouseOver={() => setModal2(true)}
                onMouseOut={() => {
                  setModal2(false);
                  setModal3(false);
                }}
              >
                <a href="javascript:void(0)">
                  <div>
                    <div>
                      <h4>PROGRAMS</h4>
                    </div>
                  </div>
                </a>
              </li>
              <li tabindex="0">
                <a href="/aboutus">
                  <div>
                    <div>
                      <h4>ABOUT US</h4>
                    </div>
                  </div>
                </a>
              </li>

              <li
                tabindex="0"
                onMouseOver={() => setModal4(true)}
                onMouseOut={() => {
                  setModal4(false);
                }}
              >
                <a href="/districts">
                  <div>
                    <div>
                      <h4>DISTRICTS</h4>
                    </div>
                  </div>
                </a>
              </li>
              <li tabindex="0">
                <a href="coming-soon">
                  <div>
                    <div>
                      <h4>GALLERY</h4>
                    </div>
                  </div>
                </a>
              </li>
              <li tabindex="0">
                <a href="/shop">
                  <div>
                    <div>
                      <h4>SHOP</h4>
                    </div>
                  </div>
                </a>
              </li>
              <li tabindex="0">
                <a href="/contactus">
                  <div>
                    <div>
                      <h4>CONTACT US</h4>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div style={{ right: "20px", position: "fixed" }}>
            <img
              style={{ marginRight: "20px" }}
              height={60}
              src={g20}
              alt="bundelkhandlogo"
              className="web-logo"
            />
            <img
              height={60}
              src={azadi}
              alt="bundelkhandlogo"
              className="web-logo"
            />
          </div>
        </div>
      </div>

      <div className="mobile-nav">
        <div className="menu-btn" onClick={() => handleModal()}>
          {modal ? (
            <i class="fas fa-times" style={{ color: "white" }} />
          ) : (
            <i class="fas fa-bars" style={{ color: "white" }} />
          )}
        </div>
        <div className="centerMobile" onClick={() => navigate("/")}>
          <img src={webLogo} alt="bundelkhandlogo" className="web-logo" />
        </div>

        {modal && (
          <div className={animate ? "animate-mobile" : "mobile-wrapper"}>
            <div className="mobile-heading">
              <div
                style={{ marginBottom: "20px" }}
                onClick={() => {
                  handleModal();
                  navigate("/");
                }}
              >
                <h2>Home</h2>
              </div>
              {mobileHeading === "Shop" ? (
                <h5 style={{ marginBottom: "20px" }}>{mobileHeading} </h5>
              ) : (
                <div
                  onClick={() => setback(!back)}
                  className="mobile-heading-wrapper"
                >
                  <KeyboardArrowLeftIcon />
                  {mobileHeading}
                </div>
              )}
            </div>

            {list1?.map((item, index) => {
              return (
                <div
                  className="wrapper-item"
                  onClick={() => handleItemMobile(item)}
                >
                  <p>{item.name}</p>
                  <KeyboardArrowRightIcon />
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* desktop modal  */}
      {modal2 && (
        <div
          class={animate ? "desktop-wrapper-animate" : "desktop-wrapper"}
          onMouseOver={() => setModal2(true)}
          onMouseOut={() => setModal2(false)}
        >
          <div class="heading">
            <h5>Mega Clusters Programs</h5>
          </div>

          {menu?.map((item, index) => {
            return (
              <div>
                <div
                  class="desktop-wrapper-item"
                  onMouseOver={() => handleItemDesktop(item)}
                  onClick={() => {
                    // navigate(`/category`, { state: item });
                    setModal2(false);
                    setModal3(false);
                  }}
                >
                  <p>{item.name}</p>
                  {/* <KeyboardArrowRightIcon /> */}
                </div>
              </div>
            );
          })}

          {modal3 && (
            <div className="desktop-wrapper-right">
              <div className="desktop-wrapper-right-heading">
                <h2>{mobileHeading}</h2>
              </div>
              <div className="dekstop-wrapper-right-content">
                {subcatList?.map((subcategory, index) => {
                  return (
                    <div className="sub-item">
                      <p
                        onClick={() => {
                          navigate(`${subcategory.link}`);
                          setModal2(false);
                          setModal3(false);
                        }}
                      >
                        {subcategory.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}

      {modal4 && (
        <div
          className="desktop-wrapper-right"
          onMouseOver={() => setModal4(true)}
          onMouseOut={() => setModal4(false)}
        >
          <div className="desktop-wrapper-right-heading">
            <h2>DISTRICTS</h2>
          </div>
          <div className="dekstop-wrapper-right-content">
            {cities?.map((item, index) => {
              return (
                <div className="sub-item">
                  <p
                    onClick={() => {
                      navigate(`${item.link}`);
                      setModal2(false);
                      setModal3(false);
                      setModal4(false);
                    }}
                  >
                    {item.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
