import React, { useState } from "react";
import "./Form.css";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

function Form() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_viksujf",
        "template_y3zxwxv",
        form.current,
        "lB-fPBNCdxKQKbefL"
      )
      .then(
        (result) => {
          alert(`Message sent successfully!`);
        },
        (error) => {
          alert(`Error retry!`);
        }
      );
    e.target.reset();
  };

  return (
    <div className="form-container">
      <div className="left">
        <h2>
          Looking to collaborate ?
          <br />
          Get in touch Artisans 👋
        </h2>
      </div>
      <div className="right">
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-card">
            <p>Your name</p>
            <input type="text" name="name" />
          </div>
          <div className="form-card">
            <p>Artisan Card No.</p>
            <input type="text" name="card" />
          </div>
          <div className="form-card">
            <p>Craft name</p>
            <input type="text" name="craft" />
          </div>
          <div className="form-card">
            <p>District name</p>
            <input type="text" name="district" />
          </div>
          <div className="form-card">
            <p>Message</p>
            <textarea name="message" id="" cols="" rows="10"></textarea>
          </div>
          <div className="form-card">
            <p>Mobile number</p>
            <input type="text" name="mobile" />
          </div>

          <input type="submit" className="submit-btn" value="Send" />
        </form>
      </div>
    </div>
  );
}

export default Form;
