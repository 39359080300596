import React, { useEffect, useState } from "react";
import "./JalaunSection.css";
import icon1 from "../../assets/logo/handPaper.png";
import icon2 from "../../assets/logo/imitationJwel.png";
import icon3 from "../../assets/logo/embroideryicon.png";

const arts = [
  {
    _id: "1",
    title: "Handmade Paper",
    icon: icon1,
    text: "Handmade paper is a traditional craft in Jalaun, a district in the Indian state of Uttar Pradesh. Skilled artisans use locally sourced materials like cotton and waste paper to create beautiful handmade paper products. The process involves pulping the fibers, spreading the pulp onto a frame, and then drying and pressing the paper sheets. Handmade paper from Jalaun is known for its unique texture, strength, and eco-friendliness. It is used to make various products like notebooks, envelopes, greeting cards, and more",
  },
  {
    _id: "2",
    title: "Imitation Jewelry",
    icon: icon2,
    text: "Imitation jewelry is a popular choice among locals and tourists alike in Jalaun. The city offers a wide range of imitation jewellery options, including necklaces, earrings, bracelets, and bangles. These pieces are often made using a combination of metals, beads, and stones, designed to mimic the look and feel of traditional gold and diamond jewelry. Whether you're looking for something to wear on a special occasion or for everyday wear, Jalaun has plenty of options to choose from when it comes to imitation jewelry.",
  },
  {
    _id: "3",
    title: "Hand Embroidery",
    icon: icon3,
    text: "Hand embroidery is one such craft that has been practiced in Jalaun for centuries. Skilled artisans use needle and thread to create intricate designs on fabrics such as silk, cotton, and velvet. The embroidery work in Jalaun is known for its fine detailing, vibrant colors, and unique patterns. This craft has not only provided a means of livelihood for many locals but has also contributed to the preservation of their cultural identity.",
  },
];

function JalaunSection() {
  const [scroll, setScroll] = useState(false);
  const [display, setDisplay] = useState({
    _id: "0",
    title: "Jalaun",
    text: "Jalaun is a city and a municipal board in Jalaun district in the Indian state of Uttar Pradesh",
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 2850);
    });
  }, []);

  return (
    <div
      className={
        scroll ? "jalaunDisctrictContainer visible" : "jalaunDisctrictContainer"
      }
    >
      <div className="left">
        <div className="subtitle">Disctrict</div>
        <div className="title">
          <h2>{display?.title}</h2>
        </div>
        <div className="description">
          <p>{display?.text}</p>
        </div>
      </div>
      <div className="right">
        {arts?.map((item) => {
          return (
            <div
              className="disctrict-handicraft-card"
              onMouseOver={() => setDisplay(item)}
              onMouseOut={() =>
                setDisplay({
                  _id: "0",
                  title: "Jalaun",
                  text: "Jalaun is a city and a municipal board in Jalaun district in the Indian state of Uttar Pradesh",
                })
              }
            >
              <div className="craft-logo">
                <img src={item.icon} alt="woodentoy" />
              </div>
              <div className="craft-name">
                <p>{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default JalaunSection;
