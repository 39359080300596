import React from "react";
import "./HomePage.css";
import Slider from "./../../components/Banner/Slider";
import AboutSection from "./../../components/AboutSection/AboutSection";
import Form from "../../components/Form/Form";
import TickerBar from "../../components/Ticker/TickerBar";
import DistrictSection from "./../../components/DistrictSection/DistrictSection";

function HomePage() {
  return (
    <div>
      <div className="homeContainer">
        <Slider />
        <TickerBar />
        <br />
        <AboutSection />
        <DistrictSection />
        <Form />
      </div>
    </div>
  );
}

export default HomePage;
