import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerTopSection">
        <div className="containers">
          <div className="mid-container">
            <div class="menu-wrapper">
              <div class="menu-box-1">
                <h3 class="pb15">Organization</h3>
                <div class="item-1">
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/aboutus">About</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/coming-soon">Guide</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/coming-soon">FAQs</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/coming-soon">Contact</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/coming-soon">Query/Feedback</a>
                  </div>
                </div>
              </div>
              <div class="menu-box-2">
                <h3 class="pb15">Cities</h3>
                <div class="item-2">
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/detail/chitrakoot">Chitrakoot</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/detail/banda">Banda</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/detail/mahoba">Mahoba</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/detail/jhansi">Jhansi</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/detail/jalaun">Jalaun</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/detail/hamirpur">Hamirpur</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/detail/lalitpur">Lalitpur</a>
                  </div>
                </div>
              </div>
              <div class="menu-box-3">
                <h3 class="pb15">Others</h3>
                <div class="item-3">
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/coming-soon">Terms of Use</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/coming-soon">Privacy Policy</a>
                  </div>
                  <div class="menu-item">
                    <img
                      src={require("../../assets/footerbg.jpg")}
                      width="15"
                      height="7"
                      class="menu-icon"
                      alt="design"
                    />
                    <a href="/coming-soon">Sitemap</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactusWrapper">
              {/* <h3 class="pb15">Contact Us</h3> */}
              <a
                href="https://goo.gl/maps/H5G8EjyDPNdDgCKY8"
                onclick="return external_link();"
                target="_blank"
              >
                <img
                  src={require("../../assets/logo/mobileLogo.png")}
                  class="img-fluid border shadow"
                />
              </a>
              <p class="py-3">
                <strong>Bundelkhand Mega Cluster</strong>
                <br />
                <strong>UPRKGVN</strong> <br />
                Lucknow-226016, Uttar Pradesh(India)
                <br />
                Email: info@bundelkhandmegacluster.com
                <br />
                {/* Fax: +91-1231231234 */}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="headerTopSection">
        <div className="containers">
          <div className="leftPanel">
            <ul>
              <li>
                <a href="https://dpiit.gov.in/" target="_blank" rel="nofollow">
                  <img
                    data-src="https://www.nsws.gov.in/s3fs/2022-03/goi-department-of-promotions-of-industry-and-internal-trade-logo.png"
                    alt="GOI - Department of Promotions of Industry and Internal Trade Logo"
                    title="GOI - Department of Promotions of Industry and Internal Trade Logo"
                    loading="lazy"
                    width="351"
                    height="30"
                    src="https://www.nsws.gov.in/s3fs/2022-03/goi-department-of-promotions-of-industry-and-internal-trade-logo.png"
                  />
                </a>
              </li>
              <li>
                <a
                  class="ii-logo"
                  href="https://www.investindia.gov.in/"
                  target="_blank"
                  rel="nofollow"
                >
                  <img
                    width="72"
                    height="25"
                    loading="lazy"
                    data-src="https://www.nsws.gov.in/s3fs/2022-03/investindia-logo.png"
                    alt="Invest India Logo"
                    title="Invest India Logo"
                    src="https://www.nsws.gov.in/s3fs/2022-03/investindia-logo.png"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="rightPanel">
            <nav className="navbar menus">
              <ul className="header-top-menu">
                <li className="list-item menu-dropdown">
                  <a href="/about-us">About</a>
                </li>
                <li className="list-item menu-dropdown">
                  <a href="/faqs" data-drupal-link-system-path="faqs">
                    FAQs
                  </a>
                </li>
                <li className="list-item menu-dropdown">
                  <a href="/portal/user-guide" target="_blank">
                    Guide
                  </a>
                </li>
                <li className="list-item menu-dropdown">
                  <a href="/contact-us">Contact</a>
                </li>
              </ul>
              <ul class="font-lang">
                <li>
                  <div class="language" tabindex="0">
                    ENG
                  </div>
                  <div class="lang-hover">
                    <p>Other Languages Coming Soon</p>
                    <div class="lang-arrow"></div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Footer;
