import React from "react";
import Ticker from "react-awesome-ticker";
import "./Tickerbar.css";

function TickerBar() {
  return (
    <div className="ticker-container">
      <Ticker
        style={{ backgroundColor: "rgb(247, 206, 104)", padding: "10px 0" }}
      >
        <div
          style={{
            display: "flex",
            marginLeft: "20px",
          }}
        >
          <h3>Upcoming</h3>
          <h3>Events</h3>
        </div>
      </Ticker>
    </div>
  );
}

export default TickerBar;
