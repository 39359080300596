import "./comingsoon.css"
import upcom from "../../assets/upcomingevent.png"
import thematic from "../../assets/thematic.jpg"

const UpcomingEvents = () => {
  

  
    return (
      
          <div className="container" >
            
            <img src={upcom} />
            <img src={thematic} />
          </div>
     
      );

   

  
   
};




export default UpcomingEvents;
