import { useState, useEffect } from "react";

import "./horizontalCarousel.css";

function HorizontalCarousel({ images }) {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((slideIndex + 1) % images?.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [slideIndex]);

  const handleNextSlide = () => {
    setSlideIndex((slideIndex + 1) % images?.length);
  };

  const handlePrevSlide = () => {
    setSlideIndex((slideIndex - 1 + images?.length) % images?.length);
  };

  const getSlides = () => {
    const slides = [];

    for (let i = slideIndex; i < slideIndex + getSlidesToShow(); i++) {
      const index = i % images?.length;
      slides.push(
        <div key={index} className="carousel__slide">
          <img src={images[index]} alt={`Slide ${index}`} />
        </div>
      );
    }

    return slides;
  };

  const getSlidesToShow = () => {
    if (window.innerWidth <= 767) {
      return 1;
    }
    return 4;
  };

  return (
    <div className="carousel">
      <button
        className="carousel__button carousel__button--prev"
        onClick={handlePrevSlide}
      >
        &#10094;
      </button>
      <div
        className={`carousel__inner ${
          window.innerWidth <= 767 ? "carousel--mobile" : ""
        }`}
      >
        {getSlides()}
      </div>
      <button
        className="carousel__button carousel__button--next"
        onClick={handleNextSlide}
      >
        &#10095;
      </button>
    </div>
  );
}

export default HorizontalCarousel;
