import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { mobile } from "./../../responsive";

const DetailPageSlider = ({ images }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [sliderItems, setSliderItems] = useState(images);
  const [index, setIndex] = useState(0);

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : sliderItems?.length - 1);
    } else {
      setSlideIndex(slideIndex < sliderItems?.length ? slideIndex + 1 : 0);
    }
  };

  const caraousel = () => {
    if (index === sliderItems?.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    setTimeout(() => caraousel(), 4000);

    return () => {};
  }, [index]);

  return (
    <Container>
      <Arrow direction="left" onClick={() => handleClick("left")}>
        <ArrowLeftOutlined />
      </Arrow>
      <Wrapper slideIndex={slideIndex}>
        {sliderItems?.map((item) => (
          <Slide
            bg={item.bg}
            key={item._id}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            <ImgContainer>
              <Image src={item.img} />
            </ImgContainer>
          </Slide>
        ))}
      </Wrapper>
      <Arrow direction="right" onClick={() => handleClick("right")}>
        <ArrowRightOutlined />
      </Arrow>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1310px;
  height: 60vh;
  display: flex;
  position: relative;
  overflow: hidden;
  ${mobile({ height: "200px" })}
  margin: auto;
  box-shadow: 0 2px 13px 0 rgba(12, 33, 67, 0.18);
  border-radius: 5px;
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.5s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
`;

const Slide = styled.div`
  width: 100vw;
  height: 60vh;
  ${mobile({ height: "200px" })}
  background-color: #${(props) => props.bg};
  transition: ease 2000ms;
`;

const ImgContainer = styled.div`
  height: 100%;
  ${mobile({ display: "contain" })}
  flex: 1;
`;

const Image = styled.img`
  height: 100%;
  width: 1310px;
  ${mobile({ width: "100%", height: "200px" })}
`;

export default DetailPageSlider;
