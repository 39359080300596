import React, { useEffect, useState } from "react";
import "./AboutSection.css";
import TickerBar from "../Ticker/TickerBar";

function AboutSection() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 310);
    });
  }, []);

  return (
    <div
      className={
        scroll ? "aboutSectionContainer visible" : "aboutSectionContainer"
      }
    >
      <div className="left">
        <div className="subtitle">Program</div>
        <div className="title">
          <h2>BundelKhand</h2>
        </div>
        <div className="description">
          <p>
            Bundelkhand Megacluster is initiative by Office of Development
            Commissioner , Ministry of textiles, Govt of India,uder CHCDS scheme
            for the seven districts of Uttar Pradesh namely Chitrakoot, Banda,
            Mahoba, Jhansi, Lalitpur, Jalaun and Hamirpur. This Project is being
            implemented by Uttar Pradesh raajya Krishi Avam Gramin Vikas Nigam
            Limited.
            <br />
            The objective is to develop these clusters with world-class
            infrastructure. The guiding principle behind the design of clusters
            would be to create world-class infrastructure that caters to the
            business needs of the local artisans & SMEs to boost production and
            export.
            <br />
          </p>
        </div>
        <div className="buttonWrapper">
          <button className="button-outline play-btn-outer">
            <em class="play-btn">&nbsp;</em>
            <strong>
              <small>Play video to know more</small>
            </strong>
          </button>
        </div>
      </div>
      <div className="right">
        <div className="points-wrapper">
          <div className="program">
            <div className="img">
              <img src="https://www.nsws.gov.in/s3fs/images/Know%20Your%20Approval%20%281%29.svg" />
            </div>
            <div className="text-r">
              <h3>Patronage of Handicrafts</h3>
              <p>
                Chanderi sarees, Wood & Stone cravings, Pottery, Terracotta,
                Embroidery, Beadwork
              </p>
            </div>
          </div>
          <div className="program">
            <div className="img">
              <img src="https://www.nsws.gov.in/s3fs/images/All%20Approval_0%20%281%29_0.svg" />
            </div>
            <div className="text-r">
              <h3>Artisans</h3>
              <p>
                The Bundelkhand cluster is able to form 400 plus Artisans & 25
                SHGs supporting the strong work force.
              </p>
            </div>
          </div>
          <div className="program">
            <div className="img">
              <img src="https://www.nsws.gov.in/s3fs/images/Real%20Time_0%20%281%29_0.svg" />
            </div>
            <div className="text-r">
              <h3>Objectives</h3>
              <p>
                Ensuring effective integration of scattered artisans, building
                their grass roots enterprises and linking them to SMEs.
              </p>
            </div>
          </div>
          <div className="program">
            <div className="img">
              <img src="https://www.nsws.gov.in/s3fs/images/Group%201421%20%281%29.svg" />
            </div>
            <div className="text-r">
              <h3>Advantages</h3>
              <p>
                Employment Generation and better living standards for the
                existing artisans
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
