
import "./comingsoon.css"
import backg from "../../assets/background.png"

const ComingSoon = () => {
  

  
    return (
      
          <div className="container" >
            
            <img src={backg} />
          </div>
     
      );

   

  
   
};




export default ComingSoon;
